<template>
  <div class="px-2 py-2">
    <b-tabs
      v-model="tab"
      class="mt-1"
      content-class="pt-0 mt-0"
      fill
      vertical
      card
      pills
      style="min-height: 500px"
    >
      <b-tab>
        <template #title>
          <b-icon-shop />
          Empresa
        </template>
        <div>
          <EmpresaCombo
            :selected="empresaSelecionada.cod_empresa"
            :selecionar="changeEmpresa"
          />
          Selecione uma empresa para configurar
        </div>
        <div v-if="empresa">
       
          <empresa-fiscal :empresa="empresaSelecionada" />

          <div class="text-right">
            <hr />
            <b-btn
              variant="success"
              size="lg"
              @click="saveConfig"
              block
              :disabled="saving"
            >
              <b-spinner small v-if="saving" />
              <b-icon-check v-else />
              Salvar</b-btn
            >
          </div>
        </div>
      </b-tab>
      <b-tab>
        <template #title>
          <b-icon-tag />
          Grupos de Produto
        </template>
        <GruposDeProdutos
          nomeCustomizado="Grupo de Produtos"
          :canAdd="false"
          :canDelete="false"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import EmpresaFiscal from "../../components/empresa/EmpresaFiscal.vue";
import GruposDeProdutos from "../Configuracoes/Cadastros/GruposDeProdutos.vue";
import EmpresaLib from "../../libs/EmpresasLib";
import EmpresaCombo from "../../components/empresa/EmpresaCombo.vue";

export default {
  components: {
    EmpresaFiscal,
    GruposDeProdutos,
    EmpresaCombo,
  },
  props: {
    empresaSelecionada: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {
    console.log("a", this.$store.state.empresa_ativa);
    this.loadEmpresa();
  },
  data() {
    return {
      tab: 0,
      empresa: null,
      saving: false,
    };
  },
  watch: {
    empresaSelecionada: {
      handler() {
        this.loadEmpresa();
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    loadEmpresa() {
      if (this.empresaSelecionada && this.empresaSelecionada.cod_empresa === -1) {
        this.empresaSelecionada = [
          this.$store.state.empresa_ativa,
          ...this.$store.state.empresa_ativa.empresas,
        ][0];
        return;
      }
      console.log("empSel", this.empresaSelecionada);
      this.empresa = JSON.parse(JSON.stringify([
        ...this.$store.state.empresa_ativa.empresas,
        this.$store.state.empresa_ativa,
      ].find(
        (x) =>
          (this.empresaSelecionada &&
            this.empresaSelecionada.cod_empresa !== -1 &&
            x.cod_empresa === this.empresaSelecionada.cod_empresa) ||
          !this.empresaSelecionada
      )));
      console.log(`empresa sel`, this.empresa);
    },
    async saveConfig() {
      this.saving = true;
      try {
        let result = await EmpresaLib.store(this.empresaSelecionada);
        console.log("result", result);
        this.$swal({
          title: "Configurações salvas com sucesso",

          icon: "success",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 1000,
          interval: 1000,
        });
      } catch (error) {
        console.error("error", error);
        this.$swal({
          title: "Erro",
          text: "Erro ao salvar configurações",
          icon: "danger",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 1000,
          interval: 1000,
        });
      }
      this.saving = false;
    },
    changeEmpresa(empresa) {
      let ob = JSON.parse(JSON.stringify(empresa));
      console.log("changeEmpresa", ob);
      this.empresa = ob;
      this.empresaSelecionada = ob;
    },
  },
};
</script>

<style lang="scss" scoped></style>
