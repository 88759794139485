<template>
  <div v-if="empresa && empresa.fiscal">
    <b-card class="my-" title="Configurações Fiscais">
      <div class="py-3 d-flex justify-content-between">
        <div>
          <b-form-group label="Plataforma de Emissão">
            <b-select
              :options="['Local', 'Integração']"
              v-model="empresa.fiscal.notaSaida.tipoEmissao"
              style="width: 130px"
          /></b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <b>Logo Danfe</b>
          <file-upload
            :max-files="1"
            v-model="empresa.fiscal.notaSaida.logo"
            :enable-title="false"
          ></file-upload>
        </div>
        <div class="col-9">
          <b-card>
            <b>Informções do Negócio</b>
            <div class="row">
              <div class="col-12 col-sm-6">
                <b-form-group>
                  <label>Razão Social</label>
                  <b-form-input type="text" v-model="empresa.fiscal.notaSaida.razao_social" />
                </b-form-group>
              </div>
              <div class="col-12 col-sm-6">
                <b-form-group>
                  <label>Fantasia</label>
                  <b-form-input type="text" v-model="empresa.fiscal.notaSaida.fantasia" />
                </b-form-group>
              </div>
              <div class="col-12 col-sm-4">
                <b-form-group>
                  <label>CNPJ</label>
                  <b-form-input
                    v-mask="'##.###.###/####-##'"
                    v-model="empresa.fiscal.notaSaida.cnpj"
                  />
                </b-form-group>
              </div>
              <div class="col-12 col-sm-4">
                <b-form-group>
                  <label>I.E.</label>
                  <b-form-input v-model="empresa.fiscal.notaSaida.ie" />
                </b-form-group>
              </div>
              <div class="col-12 col-sm-4">
                <b-form-group>
                  <label>I.M.</label>
                  <b-form-input v-model="empresa.fiscal.notaSaida.im" />
                </b-form-group>
              </div>
            </div>
          </b-card>
        </div>
        <div
          class="col-12"
          v-if="
            empresa && empresa.fiscal && empresa.fiscal.notaSaida && empresa.fiscal.notaSaida.endereco
          "
        >
          <b-card class="mt-2">
            <h4><i class="fa fa-map-marker-alt" /> Endereço</h4>
            <div class="row">
              <div class="col-12 col-sm-2">
                <b-form-group>
                  <label>CEP</label>
                  <b-form-input
                    v-mask="'#####-###'"
                    v-model="empresa.fiscal.notaSaida.endereco.cep"
                    @input="findCEP"
                  />
                </b-form-group>
              </div>
              <div class="col-12 col-sm-8">
                <b-form-group>
                  <label>Logradouro</label>
                  <b-form-input v-model="empresa.fiscal.notaSaida.endereco.logradouro" />
                </b-form-group>
              </div>
              <div class="col-12 col-sm-2">
                <b-form-group>
                  <label>Número</label>
                  <b-form-input v-model="empresa.fiscal.notaSaida.endereco.numero" />
                </b-form-group>
              </div>
              <div class="col-12 col-sm-4">
                <b-form-group>
                  <label>Bairro</label>
                  <b-form-input v-model="empresa.fiscal.notaSaida.endereco.bairro" />
                </b-form-group>
              </div>

              <div class="col-12 col-sm-2">
                <b-form-group>
                  <label>Cod. IBGE</label>
                  <b-form-input v-model="empresa.fiscal.notaSaida.endereco.cidadeIBGE" />
                </b-form-group>
              </div>
              <div class="col-12 col-sm-4">
                <b-form-group>
                  <label>Cidade</label>
                  <b-form-input v-model="empresa.fiscal.notaSaida.endereco.cidade" />
                </b-form-group>
              </div>
              <div class="col-12 col-sm-2">
                <b-form-group>
                  <label>UF</label>
                  <b-form-input v-model="empresa.fiscal.notaSaida.endereco.uf" />
                </b-form-group>
              </div>

              <div class="col-12">
                <b-form-group>
                  <label>Complemento</label>
                  <b-form-input v-model="empresa.fiscal.notaSaida.endereco.complemento" />
                </b-form-group>
              </div>
              <div class="col-12 col-sm-6">
                <b-form-group>
                  <label>Fone</label>
                  <b-form-input
                    v-mask="'(##) ####-####'"
                    v-model="empresa.fiscal.notaSaida.endereco.fone"
                  />
                </b-form-group>
              </div>
            </div>
          </b-card>
        </div>

        <div class="col-12" v-if="empresa && empresa.fiscal && empresa.fiscal.notaSaida.certificado">
          <b-card class="mt-2">
            <b>Informe o certificado</b>
            <div class="d-flex">
              <file-upload
                :max-files="1"
                v-model="empresa.fiscal.notaSaida.certificado.arquivo"
                :enable-title="false"
              ></file-upload>
              <div class="pl-" v-if="empresa.fiscal.notaSaida.certificado.arquivo">
                <b>Senha</b>
                <b-input v-model="empresa.fiscal.notaSaida.certificado.senha" />
              </div>
            </div>
          </b-card>
        </div>
        <div class="col-12">
          <b-card class="mt-2">
            <b>Configução de Ambiente</b>
            <div class="my-4">
              <b>Ativo</b>
              <b-form-radio-group v-model="empresa.fiscal.notaSaida.ambiente">
                <b-form-radio value="2">Homologação</b-form-radio>
                <b-form-radio value="1">Produção</b-form-radio>
              </b-form-radio-group>
            </div>
            <div
              class="d-flex"
              v-if="empresa && empresa.fiscal && empresa.fiscal.notaSaida.configAmbiente"
            >
              <b-card class="flex-1 w-50">
                <b>Homologação</b>
                <hr />
                <div>
                  <b>Id CSC</b>
                  <b-input
                    v-model="empresa.fiscal.notaSaida.configAmbiente.homologacao.idCSC"
                  />
                </div>
                <div>
                  <b>CSC</b>
                  <b-input
                    v-model="empresa.fiscal.notaSaida.configAmbiente.homologacao.CSC"
                  />
                </div>
                <hr />
                <div>
                  <b>Serie</b>
                  <b-input
                    v-model="empresa.fiscal.notaSaida.configAmbiente.homologacao.serie"
                  />
                </div>
                <div>
                  <b>Ultima NFCe</b>
                  <b-input
                    v-model="empresa.fiscal.notaSaida.configAmbiente.homologacao.ultimaNFCe"
                  />
                </div>
              </b-card>
              <b-card class="flex-1 w-50 ml-2">
                <b>Produção</b>
                <hr />

                <div>
                  <b>Id CSC</b>
                  <b-input v-model="empresa.fiscal.notaSaida.configAmbiente.producao.idCSC" />
                </div>
                <div>
                  <b>CSC</b>
                  <b-input v-model="empresa.fiscal.notaSaida.configAmbiente.producao.CSC" />
                </div>
                <hr />
                <div>
                  <b>Serie</b>
                  <b-input v-model="empresa.fiscal.notaSaida.configAmbiente.producao.serie" />
                </div>
                <div>
                  <b>Ultima NFCe</b>
                  <b-input
                    v-model="empresa.fiscal.notaSaida.configAmbiente.producao.ultimaNFCe"
                  />
                </div>
              </b-card>
            </div>
          </b-card>
        </div>
      </div>
    </b-card>
    <b-card title="Nota de Saída" class="mt-2">
      <!-- <div class="row">
        <div class="col-12 col-sm-3">
          <b>CFOP</b><br />
          <b-input v-model="empresa.fiscal.notaSaida.CFOP" />
        </div>
        <div class="col-12 col-sm-9">
          <b>Natureza da Operação</b><br />
          <b-input v-model="empresa.fiscal.notaSaida.naturezaOperacao" />
        </div>
      </div>
      <hr /> -->
      <div class="row">
        <div class="col-12 mt-2">
          <b>Emissão automática</b>
          <hr />
          <b-card>
            <b-checkbox switch v-model="empresa.fiscal.notaSaida.emissaoAutomaticaOnline"
              >Pedidos Online - Emitir Automáticamente</b-checkbox
            >
            <b class="mt-2">Formas de Pagamento</b>
            <v-select
              :options="formas_pagamento"
              multiple
              v-model="empresa.fiscal.notaSaida.emissaoAutomaticaOnlineFormasPagamento"
            />
            <b class="mt-2">Produto</b>
            <v-select
              :options="produtos"
              v-model="empresa.fiscal.notaSaida.emissaoAutomaticaOnlineProduto"
              label="nome"
              :reduce="(sel) => sel.cod_produto"
            />
          </b-card>
          <table class="table">
            <thead>
              <tr>
                <th class="text-center">Tipo Venda</th>
                <th class="text-center">Valor Limite/Mes</th>
                <th class="text-center">Valor Limite/Diário</th>
                <th class="text-center">Periodo</th>
                <th class="text-center">Forma Pagamento</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="tp in empresa.fiscal.notaSaida.emissaoAutomatica"
                :key="`emi_${tp.tipoVenda}`"
              >
                <td>
                  <b-checkbox switch v-model="tp.ativo">{{ tp.tipoVenda }}</b-checkbox>
                </td>
                <td>
                  <b-input v-model="tp.limite" type="number" class="text-center" />
                </td>
                <td>
                  <b-input
                    v-model="tp.limite_diario"
                    type="number"
                    class="text-center"
                  />
                </td>
                <td class="text-center">
                  <b-input
                    v-model="tp.periodoDe"
                    class="text-center"
                    style="max-width: 40%; display: inline; margin-right: 2px"
                  />
                  <b-input
                    v-model="tp.periodoAte"
                    class="text-center"
                    style="max-width: 40%; display: inline"
                  />
                </td>
                <td class="pl-1 pr-1">
                  <v-select
                    :options="formas_pagamento"
                    multiple
                    v-model="tp.formasPagamento"
                  />
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>Total</th>
                <th class="text-center">
                  {{
                    empresa.fiscal.notaSaida.emissaoAutomatica.reduce((ret, vl) => {
                      ret += this.formatValorReal(vl.limite);
                      return ret;
                    }, 0) | currencyMask
                  }}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
// import ProdutoLib from "../../libs/ProdutoLib";
import EstoqueLib from "../../libs/EstoqueLib";
import FileUpload from "../common/FileUpload.vue";
let emissaoAutomaticaPadrao = [
  {
    ativo: false,
    tipoVenda: "Balcão",
    limite: 0,
    periodoDe: 1,
    periodoAte: 31,
    formasPagamento: [],
  },
  {
    ativo: false,
    tipoVenda: "Buscar",
    limite: 0,
    periodoDe: 1,
    periodoAte: 31,
    formasPagamento: [],
  },
  {
    ativo: false,
    tipoVenda: "Entrega",
    limite: 0,
    periodoDe: 1,
    periodoAte: 31,
    formasPagamento: [],
  },
  {
    ativo: false,
    tipoVenda: "Mesa",
    limite: 0,
    periodoDe: 1,
    periodoAte: 31,
    formasPagamento: [],
  },
];
import CentroCustosLib from "../../libs/CentrosDeCustoLib";

export default {
  components: {
    FileUpload,
  },
  props: {
    empresa: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.carregaProdutos();
    this.carregarCentroCustos();
    this.verifyCampos();
  },
  data() {
    return {
      loaded: false,
    
      formas_pagamento: ["PIX", "Cartão", "Dinheiro", "Vale", "Dim. + Cart.", "Online"],
      produtos: [],
      custos: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: true,
      },
    };
  },
  watch: {
    // empresa: {
    //   handler() {
    //     let ob = JSON.parse(JSON.stringify(this.empresa));

    //     // // if (!this.empresa.fiscal.notaSaida.emissaoAutomatica) {
    //     // //   this.empresa.fiscal.notaSaida.emissaoAutomatica =
    //     // //     emissaoAutomaticaPadrao;
    //     // // }
    //     // // this.empresa.fiscal.notaSaida.emissaoAutomatica = JSON.parse(
    //     // //   JSON.stringify(emissaoAutomaticaPadrao)
    //     // // );
    //     // this.$forceUpdate();

    //     this.empresa = ob;
    //     //console.log("changeEmpresa 2", this.empresa);
    //     // this.$forceUpdate();
    //     // this.verifyCampos();
    //   },
    //   deep: true,
    // },
    // empresa: {
    //   handler() {
    //     if (this.loaded) this.$emit("changed", this.empresa);
    //   },
    //   deep: true,
    // },
  },
  computed: {},
  methods: {
    verifyCampos() {
      console.log("empp", JSON.parse(JSON.stringify(this.empresa)));

      if (this.empresa) {
        if (!this.empresa.fiscal) {
          this.empresa.fiscal = {
            notaSaida: {
              CFOP: "",
              naturezaOperacao: "",
              emissaoAutomatica: emissaoAutomaticaPadrao,
            },
          };
        }
        if (!this.empresa.fiscal.notaSaida.emissaoAutomatica) {
          this.empresa.fiscal.notaSaida.emissaoAutomatica = emissaoAutomaticaPadrao;
        }

        if (!this.empresa.fiscal.notaSaida) {
          this.empresa.fiscal.notaSaida = {};
        }
        if (!this.empresa.fiscal.notaSaida.tipoEmissao) {
          this.empresa.fiscal.notaSaida.tipoEmissao = "Local";
        }

        if (!this.empresa.fiscal.emissaoAutomaticaOnline) {
          this.empresa.fiscal.emissaoAutomaticaOnline = false;
        }
        if (!this.empresa.fiscal.emissaoAutomaticaOnlineProduto) {
          this.empresa.fiscal.emissaoAutomaticaOnlineProduto = null;
        }
        if (!this.empresa.fiscal.emissaoAutomaticaOnlineFormasPagamento) {
          this.empresa.fiscal.emissaoAutomaticaOnlineFormasPagamento = [];
        }

        if (!this.empresa.fiscal.notaSaida.CFOP) {
          this.empresa.fiscal.notaSaida.CFOP = "";
        }

        if (!this.empresa.fiscal.notaSaida.logo) {
          this.empresa.fiscal.notaSaida.logo = null;
        }

        if (!this.empresa.fiscal.notaSaida.naturezaOperacao) {
          this.empresa.fiscal.notaSaida.naturezaOperacao = "";
        }

        if (!this.empresa.fiscal.notaSaida.emissaoAutomatica) {
          this.empresa.fiscal.notaSaida.emissaoAutomatica = emissaoAutomaticaPadrao;
        }

        if (!this.empresa.fiscal.notaSaida.endereco) {
          this.empresa.fiscal.notaSaida.endereco = {
            logradouro: "",
            numero: "",
            bairro: "",
            cidade: "",
            uf: "",
            cep: "",
            complemento: "",
            fone: "",
            cidadeIBGE: "",
          };
        }

        if (!this.empresa.fiscal.notaSaida.endereco.fone) {
          this.empresa.fiscal.notaSaida.endereco.fone = "";
        }
        if (!this.empresa.fiscal.notaSaida.endereco.cep) {
          this.empresa.fiscal.notaSaida.endereco.cep = "";
        }
        if (!this.empresa.fiscal.notaSaida.endereco.logradouro) {
          this.empresa.fiscal.notaSaida.endereco.logradouro = "";
        }
        if (!this.empresa.fiscal.notaSaida.endereco.numero) {
          this.empresa.fiscal.notaSaida.endereco.numero = "";
        }
        if (!this.empresa.fiscal.notaSaida.endereco.bairro) {
          this.empresa.fiscal.notaSaida.endereco.bairro = "";
        }
        if (!this.empresa.fiscal.notaSaida.endereco.cidade) {
          this.empresa.fiscal.notaSaida.endereco.cidade = "";
        }
        if (!this.empresa.fiscal.notaSaida.endereco.cidadeIBGE) {
          this.empresa.fiscal.notaSaida.endereco.cidadeIBGE = "";
        }
        if (!this.empresa.fiscal.notaSaida.endereco.uf) {
          this.empresa.fiscal.notaSaida.endereco.uf = "";
        }
        if (!this.empresa.fiscal.notaSaida.endereco.complemento) {
          this.empresa.fiscal.notaSaida.endereco.complemento = "";
        }
        if (!this.empresa.fiscal.notaSaida.certificado) {
          this.empresa.fiscal.notaSaida.certificado = {
            senha: "",
            arquivo: [],
          };
        }

        if (!this.empresa.fiscal.notaSaida.ambiente) {
          this.empresa.fiscal.notaSaida.ambiente = "1";
        }
        if (!this.empresa.fiscal.notaSaida.configAmbiente) {
          this.empresa.fiscal.notaSaida.configAmbiente = {
            homologacao: {
              idCSC: "1",
              CSC: "",
              serie: "",
              ultimaNFCe: 0,
            },
            producao: {
              idCSC: "1",
              CSC: "",
              serie: "",
              ultimaNFCe: 0,
            },
          };
        }
        this.empresa = JSON.parse(JSON.stringify(this.empresa));
      }
      this.loaded = true;
    },
    async findCEP() {
      if (
        this.empresa &&
        this.empresa.fiscal &&
        this.empresa.fiscal.notaSaida &&
        this.empresa.fiscal.notaSaida.endereco.cep
      ) {
        //find cep by viacep
        const cep = this.empresa.fiscal.notaSaida.endereco.cep;
        if (cep.length < 9) return;
        console.log("finding cep", cep);
        const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
        const data = await response.json();
        console.log("result json", data);
        if (data && data.erro) {
          this.$swal.fire({
            title: "CEP não encontrado",
            icon: "error",
            toast: true,
            position: "top-end",
            timer: 1500,
            showConfirmButton: false,
            timerProgressBar: true,
          });
          return;
        }
        if (data) {
          this.empresa.fiscal.notaSaida.endereco.logradouro = data.logradouro;
          this.empresa.fiscal.notaSaida.endereco.bairro = data.bairro;
          this.empresa.fiscal.notaSaida.endereco.cidade = data.localidade;
          this.empresa.fiscal.notaSaida.endereco.uf = data.uf;
          this.$forceUpdate();
        }
      }
    },
    async carregaProdutos() {
      console.log("loading produtos")
      this.produtos = await EstoqueLib.getProdutos({},false,true);
      console.log("produtos !!!", this.produtos)
    },
    async carregarCentroCustos() {
      this.custos = (await CentroCustosLib.get()).map((it) => {
        return {
          text: it.nome,
          id: it.cod_custo,
        };
      });
      console.log("-.>", this.custos);
    },
  },
};
</script>

<style lang="scss" scoped></style>
